<template>
  <nav class="navbar navbar-top navbar-expand-lg" id="navbarCombo" data-navbar-top="combo" data-move-target="#navbarVerticalNav" >
    <div class="navbar-logo">
        <button class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
        <a class="navbar-brand me-1 me-sm-3" href="/">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center"><img src="@/assets/img/logo.png" alt="IASC" width="27" />
              <p class="logo-text ms-2 d-none d-sm-block">Transporte</p>
            </div>
          </div>
        </a>
      </div>
      
      <div class="collapse navbar-collapse navbar-top-collapse order-1 order-lg-0 justify-content-center" id="navbarTopCollapse">
        <ul class="navbar-nav navbar-nav-top" >
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/clientes" >
              <span class="uil fs-0 uil-users-alt"></span>Clientes
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/operadores" >
              <span class="uil fs-0 uil-user-md"></span>Operadores
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/vehiculos" >
              <span class="uil fs-0 uil-truck"></span>Vehículos
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/depositos" >
              <span class="uil fs-0 uil-file-contract-dollar"></span>Depósitos
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/remisiones" >
              <span class="uil fs-0 uil-receipt"></span>Remisiones
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/facturas" >
              <span class="uil fs-0 uil-invoice"></span>Facturas
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/liquidaciones" >
              <span class="uil fs-0 uil-create-dashboard"></span>Liquidación
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/antiguedad-saldos" >
              <span class="uil fs-0 uil-diary"></span>Ant. Saldos
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link lh-1" to="/consulta" >
              <span class="uil fs-0 uil-search"></span>Consulta
            </router-link>
          </li>
          <li class="nav-item ">
            <a class="nav-link lh-1 cursor-pointer" @click="openParams()" >
              <span class="uil fs-0 uil-apps"></span>Parámetros
            </a>
          </li>
         
        </ul>
      </div>
      <ul class="navbar-nav navbar-nav-icons flex-row">
        <li class="nav-item">
          <div class="theme-control-toggle fa-icon-wait px-2">
            <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="iascTheme" value="dark" id="themeControlToggle" />
            <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"  title="Cambiar tema"><Moon class="icon" /></label>
            <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Cambiar tema"><Sun class="icon" /></label>
          </div>
        </li>
        <li v-if="routePath != '/notificaciones'" class="nav-item dropdown">
          <a class="nav-link position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside"><Bell style="height:20px;width:20px;" /><span v-if="nuevosMensajes > 0" class="position-absolute top-2 translate-middle badge rounded-pill bg-danger-500">{{(nuevosMensajes > 100) ? '99+' : nuevosMensajes}}<span class="visually-hidden">nuevos mensajes</span></span></a>
          <div class="dropdown-menu dropdown-menu-end notification-dropdown-menu py-0 shadow border border-300 navbar-dropdown-caret" id="navbarDropdownNotfication" aria-labelledby="navbarDropdownNotfication">
            <div class="card position-relative border-0">
              <div class="card-header p-2">
                <div class="d-flex justify-content-between">
                  <h5 class="text-black mb-0">Notificaciones</h5>
                  <button v-if="notificaciones.length > 0" class="btn btn-link p-0 fs--1 fw-normal" type="button" @click="marcarTodas()">Marcar todas como leidas</button>
                </div>
              </div>
              
              <div class="card-body p-0" v-if="notificaciones.length > 0">
                <div class="scrollbar-overlay" style="height: 27rem;">
                  <div class="border-300">
                    <div v-for="(notificacion,z) in notificaciones" :key="z" class="px-2 px-sm-3 py-3 border-300 notification-card position-relative  border-bottom" :class="[(notificacion.nota.leida ? 'read' : 'unread')]">
                      <div class="d-flex align-items-center justify-content-between position-relative">
                        <div class="d-flex">
                          <Profile class="status-online me-3" :usuario="{nombre: notificacion.afectador.nombre, apellidos: notificacion.afectador.apellidos, ruta_foto: notificacion.afectador.foto}" :size="'m'" />
                         
                          <div class="flex-1 me-sm-3">
                            <h4 class="fs--1 text-black">{{notificacion.afectador.nombre}} {{ notificacion.afectador.apellidos }}</h4>
                            <p class="fs--1 text-1000 mb-2 mb-sm-3 fw-normal">
                              <TagPlusOutline class="text-success" v-if="notificacion.nota.tipo == 'creacion'" />
                              <AccountTag class="text-warning" v-if="notificacion.nota.tipo == 'asignacion'" />
                              <ListStatus  class="text-success"  v-if="notificacion.nota.tipo == 'estatus'" />
                              <MessagePlusOutline class="text-primary"  v-if="notificacion.nota.tipo == 'seguimiento'" />
                              <AttachmentPlus class="text-danger"  v-if="notificacion.nota.tipo == 'adjunto'" />
                              <router-link :to="'/ticket/'+notificacion.nota.ticket_id" @click="marcar(notificacion.nota)">{{notificacion.nota.nota}}</router-link><span class="ms-2 text-400 fw-bold fs--2">{{formatFromNow(notificacion.nota.createdAt)}}</span></p>
                            <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold">{{formato(notificacion.nota.createdAt,"h:mm a")}} </span>{{formato(notificacion.nota.createdAt,"d MMMM,YYYY")}}</p>
                          </div>
                        </div>
                       
                        <div class="font-sans-serif d-none d-sm-block">
                          <EmailOpenOutline v-if="notificacion.nota.leida"  />
                          <EmailOutline v-if="!notificacion.nota.leida" />
                          <button  v-if="!notificacion.nota.leida" class="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle" type="button" data-stop-propagation="data-stop-propagation" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs--2 text-900"></span></button>
                          <div  v-if="!notificacion.nota.leida" class="dropdown-menu dropdown-menu-end py-2"><a class="dropdown-item" href="#" @click="marcar(notificacion)">Marcar como leido</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0" v-else>
                <p class="text-center p-4">NO TIENES NOTIFICACIONES</p>
              </div>
              <div class="card-footer p-0 border-top border-0">
                <div v-if="notificaciones.length > 0" class="my-2 text-center fw-bold fs--2 text-600"><router-link class="fw-bolder" to="/notificaciones">Ver todas las notificaciones</router-link></div>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" id="navbarDropdownNindeDots" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" data-bs-auto-close="outside" aria-expanded="false">
            <svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </a>

          <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-nide-dots shadow border border-300" aria-labelledby="navbarDropdownNindeDots">
            <div class="card bg-white position-relative border-0">
              <div class="card-body pt-3 px-3 pb-0 overflow-auto scrollbar" style="height: 20rem;">
                <div class="row text-center align-items-center gx-0 gy-0">
                  <div class="col-4">
                    <a class="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" target="_blank" href="https://youtu.be/4Z4w49Zq9TA">
                      <img src="@/assets/img/nav-icons/youtube.png" alt="" width="30" />
                      <p class="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Carta Porte</p>
                    </a>
                  </div>
                  <div class="col-4">
                    <a class="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" target="_blank" href="https://youtu.be/-SENBWogjs4?si=Pe6eFE8CNkbMpYLT">
                      <img src="@/assets/img/nav-icons/youtube.png" alt="" width="30" />
                      <p class="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Importar Datos</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
       
        <li class="nav-item dropdown">
          <a class="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
            <Profile :usuario="user" :size="'l'"  />
          </a>

          <Parameters v-if="openParameters" @close="openParameters = false" class="popup-static" @goAhead="saveParameters()"> 
            <template v-slot:title>Parámetros</template>
            <template v-slot:body>
              <div id="parametros" class="row p-3">
                <nav class="col-2">
                  <ul class="nav flex-column " role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="datos-tab" data-bs-toggle="tab" data-bs-target="#nav-datos" type="button" role="tab" aria-controls="datos" aria-selected="true">Datos</a></li>
                    <li class="nav-item"><a class="nav-link" id="general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="general" aria-selected="true">General</a></li>
                    <li class="nav-item"><a class="nav-link" id="contabilidad-tab" data-bs-toggle="tab" data-bs-target="#nav-contabilidad" type="button" role="tab" aria-controls="contabilidad" aria-selected="true">Contabilidad</a></li>
                    <li class="nav-item"><a class="nav-link" id="tipos-tab" data-bs-toggle="tab" data-bs-target="#nav-tipos" type="button" role="tab" aria-controls="tipos" aria-selected="true">Tipos de Impuestos</a></li>
                    <li class="nav-item"><a class="nav-link" id="nomina-tab" data-bs-toggle="tab" data-bs-target="#nav-nomina" type="button" role="tab" aria-controls="nomina" aria-selected="true">Nomina</a></li>
                    <li class="nav-item"><a class="nav-link" id="permisionarios-tab" data-bs-toggle="tab" data-bs-target="#nav-permisionarios" type="button" role="tab" aria-controls="permisionarios" aria-selected="true">Permisionarios</a></li>
                    <li class="nav-item"><a class="nav-link" id="servidor-tab" data-bs-toggle="tab" data-bs-target="#nav-servidor" type="button" role="tab" aria-controls="servidor" aria-selected="true">Servidor SMTP</a></li>
                    <li class="nav-item"><a class="nav-link" id="avisos-tab" data-bs-toggle="tab" data-bs-target="#nav-avisos" type="button" role="tab" aria-controls="avisos" aria-selected="true">Avisos</a></li>
                    <li class="nav-item"><a class="nav-link" id="formatos-tab" data-bs-toggle="tab" data-bs-target="#nav-formatos" type="button" role="tab" aria-controls="formatos" aria-selected="true">Formatos</a></li>
                    <li class="nav-item"><a class="nav-link" id="facil-tab" data-bs-toggle="tab" data-bs-target="#nav-facil" type="button" role="tab" aria-controls="facil" aria-selected="true">Fácil. Administrativas</a></li>
                    <li class="nav-item"><a class="nav-link" id="factura-tab" data-bs-toggle="tab" data-bs-target="#nav-factura" type="button" role="tab" aria-controls="factura" aria-selected="true">Factura Electrónica</a></li>
                    <li class="nav-item"><a class="nav-link" id="parametros-tab" data-bs-toggle="tab" data-bs-target="#nav-parametros" type="button" role="tab" aria-controls="parametros" aria-selected="true">Parámetros</a></li>
                    <li class="nav-item"><a class="nav-link" id="remisiones-tab" data-bs-toggle="tab" data-bs-target="#nav-remisiones" type="button" role="tab" aria-controls="remisiones" aria-selected="true">Remisiones</a></li>
                    <li class="nav-item"><a class="nav-link" id="facturas-tab" data-bs-toggle="tab" data-bs-target="#nav-facturas" type="button" role="tab" aria-controls="facturas" aria-selected="true">Facturas</a></li>
                    <li class="nav-item"><a class="nav-link" id="gps-tab" data-bs-toggle="tab" data-bs-target="#nav-gps" type="button" role="tab" aria-controls="gps" aria-selected="true">GPS</a></li>
                    <li class="nav-item"><a class="nav-link" id="app-tab" data-bs-toggle="tab" data-bs-target="#nav-app" type="button" role="tab" aria-controls="app" aria-selected="true">App Móvil</a></li>
                    <li class="nav-item"><a class="nav-link" id="contabilidad2-tab" data-bs-toggle="tab" data-bs-target="#nav-contabilidad2" type="button" role="tab" aria-controls="contabilidad2" aria-selected="true">Contabilidad 2</a></li>
                    <li class="nav-item"><a class="nav-link" id="ultragas-tab" data-bs-toggle="tab" data-bs-target="#nav-ultragas" type="button" role="tab" aria-controls="ultragas" aria-selected="true">UltraGas</a></li>
                    <li class="nav-item"><a class="nav-link" id="cartaporte-tab" data-bs-toggle="tab" data-bs-target="#nav-cartaporte" type="button" role="tab" aria-controls="cartaporte" aria-selected="true">Comp. Carta Porte</a></li>
                  </ul>
                </nav>
                <div class="col-10 tab-content border" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-datos" role="tabpanel" aria-labelledby="nav-datos-tab">
                    <div class="row ">
                        <label class="col-2 text-nowrap">Extensión</label>
                        <div class="col-3 d-flex">
                            <input type="text" class="form-control form-control-sm text-uppercase"  v-model="parametros.extension" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Nombre</label>
                        <div class="col-10 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.cia" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Dirección</label>
                        <div class="col-10 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.direccion" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Colonia</label>
                        <div class="col-6 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.colonia" />													
                        </div>
                    </div>	
                    <div class="row ">
                      <div class="col-8">
                        <label class="col-2 text-nowrap">Ciudad</label>
                        <div class="col-6 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ciudad" />													
                        </div>
                      </div>	
                      <div class="col-4">
                        <lookUpCtrl :objeto="parametros" :label="'SAT'" :cols="[2,4,9]" :archivo="string" :hideDescripcion="true" :campo="string" :descripcion="string" :model="'cvesatmunicipio'" v-model="parametros.cvesatmunicipio" />
                      </div>	
                    </div>	
                    <div class="row ">
                      <div class="col-8">
                        <div class="row ">
                          <label class="col-2 text-nowrap">Estado</label>
                          <div class="col-6 d-flex">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.estado" />													
                          </div>
                        </div>
                      </div>	
                      <div class="col-4">
                        <lookUpCtrl :objeto="parametros" :label="'SAT'" :cols="[2,4,9]" :archivo="string" :hideDescripcion="true" :campo="string" :descripcion="string" :model="'cvesatestado'" v-model="parametros.cvesatestado" />
                      </div>	
                    </div>	
                    <div class="row ">
                      <div class="col-8">
                        <div class="row ">
                          <label class="col-2 text-nowrap">País</label>
                          <div class="col-6 d-flex">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.pais" />													
                          </div>
                        </div>
                      </div>	
                      <div class="col-4">
                        <lookUpCtrl :objeto="parametros" :label="'SAT'" :cols="[2,4,9]" :archivo="string" :hideDescripcion="true" :campo="string" :descripcion="string" :model="'cvesatpais'" v-model="parametros.cvesatpais" />
                      </div>	
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">C. Postal</label>
                        <div class="col-3 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.cp" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">R.F.C.</label>
                        <div class="col-4 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.rfc" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Página Web</label>
                        <div class="col-10 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.web" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Email</label>
                        <div class="col-10 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.email" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Teléfono</label>
                        <div class="col-6 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.telefonos" />													
                        </div>
                    </div>
                    <div class="row ">
                        <label class="col-2 text-nowrap">Fax</label>
                        <div class="col-6 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fax" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Régimen Fiscal</label>
                        <div class="col-10 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.regimen" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Reg Canacar</label>
                        <div class="col-5 d-flex">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.canacar" />													
                        </div>
                    </div>	
                  </div>
                  <div class="tab-pane fade" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                    <div class="row">
                      <div class="col-7">
                        <div class="row ">
                            <label class="col-4 text-nowrap">ID Liquidación</label>
                            <div class="col-8">
                                <input type="text" class="form-control form-control-sm" v-model="parametros.idliquida" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-4 text-nowrap">Comisión Operador</label>
                            <div class="col-3 d-flex">
                                <input type="number" class="form-control form-control-sm" v-model="parametros.comision" />
                            </div>
                            <label class="col-2 text-nowrap">x Km</label>
                            <div class="col-3 d-flex">
                                <input type="number" class="form-control form-control-sm" v-model="parametros.comisionxkm" />
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-4 text-nowrap">Num Cliente Vacios</label>
                            <div class="col-4">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.ctevacios" />													
                            </div>
                        </div>	
                        <div class="row">
                          <label class="col-4 text-nowrap text-right">Formatos</label>
                          <div class="col-8">
                            <select class="form-control form-control-sm" v-model="parametros.formatofecha">
                              <option value="" disabled>Selecciona un formato...</option>
                              <option value="@D5">dd/mm/aa</option>
                              <option value="@D6">dd/mm/aaaa</option>
                              <option value="@D1">mm/dd/aa</option>
                              <option value="@D2">mm/dd/aaaa</option>
                              <option value="@D9">aa/mm/dd</option>
                              <option value="@D10">aaaa/mm/dd</option>
                              <option value="@D7">dd-mm-aa</option>
                              <option value="@D8">dd-mm-aaaa</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-4 text-nowrap text-right">Asignar en Liq.</label>
                          <div class="col-8">
                            <select class="form-control form-control-sm" v-model="parametros.asignaliq">
                              <option value="" disabled>Selecciona una opcion...</option>
                              <option value="FAC">Facturas</option>
                              <option value="REM">Remisiones</option>
                              <option value="TRA">Traficos</option>
                              <option value="TOD">Todo</option>
                            </select>
                          </div>
                        </div>
                        <div class="row ">
                            <label class="col-4 text-nowrap">Concepto Banco</label>
                            <div class="col-3 ">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.conceptoantic" />													
                            </div>
                        </div>	
                        <div class="form-check">
                          <input class="form-check-input" v-model="parametros.restringirestatus" type="checkbox" true-value="S" false-value="N"  id="restringirestatus">
                          <label class="form-check-label" for="restringirestatus">Restringir Estatus de Operador en Tráfico</label>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class=" w-100 foto-licencia base-image-input text-center">
                            <label for="inputfilelogo" class="cursor-pointer" >
                                <img class="rounded img-fluid " :src="logotipo" alt="" style="max-height:200px" />
                            </label>
                            <input class="d-none" id="inputfilelogo" ref="inputfilelogo" type="file" @change="uploadImage($event)" />
                        </div>
                        <button type="button" @click="$refs.inputfilelogo.click()" class="btn btn-outline-secondary btn-block">Seleccionar Logotipo</button>
                      </div>
                      <div class="col-6">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.actanticipos "  type="checkbox" true-value="S" false-value="N" id="activaanticipos">
                          <label class="form-check-label" for="activaanticipos">Activa Anticipos</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activaliq "  type="checkbox" true-value="S" false-value="N" id="activaliquidaciones">
                          <label class="form-check-label" for="activaliquidaciones">Activa Liquidaciones</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.liqgenchq"  type="checkbox" true-value="S" false-value="N" id="liqgenerapago">
                          <label class="form-check-label" for="liqgenerapago">Liq. Genera Pago</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.quitarcomliq "  type="checkbox" true-value="S" false-value="N" id="quitarcomisionenliq">
                          <label class="form-check-label" for="quitarcomisionenliq">Quitar Comision en Liq.</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activaacc "  type="checkbox" true-value="S" false-value="N" id="activaaccesorios">
                          <label class="form-check-label" for="activaaccesorios">Activa Accesorios</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.soloflete "  type="checkbox" true-value="S" false-value="N" id="basesoloflete">
                          <label class="form-check-label" for="basesoloflete">Base (solo Flete)</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.usarfactorbase "  type="checkbox" true-value="S" false-value="N" id="usarfactorbase">
                          <label class="form-check-label" for="usarfactorbase">Usar Factor Base</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.decimalpdas "  type="checkbox" true-value="S" false-value="N" id="usardecencant">
                          <label class="form-check-label" for="usardecencant">Usar decimales en Cantidades</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.nokmsdiesel "  type="checkbox" true-value="S" false-value="N" id="nopedirkmdiesel">
                          <label class="form-check-label" for="nopedirkmdiesel">No pedir Kms en diesel</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.facdieselrecepcion "  type="checkbox" true-value="S" false-value="N" id="ligarfacdieselrec">
                          <label class="form-check-label" for="ligarfacdieselrec">Ligar Factura Diesel con Recepción</label>
                        </div>
                        <div class="form-check" v-if="$ext == 'her' || $ext == 'tra' || $ext == 'thr'">
                          <input class="form-check-input"  v-model="parametros.liqnogenerapmo "  type="checkbox" true-value="S" false-value="N" id="liqnogenerapmo">
                          <label class="form-check-label" for="liqnogenerapmo">Liq. NO Generar Préstamo</label>
                        </div>
                        <div class="form-check" v-if="$ext == 'her' || $ext == 'tra' || $ext == 'thr'">
                          <input class="form-check-input"  v-model="parametros.usarturnos "  type="checkbox" true-value="S" false-value="N" id="aplicarturnos">
                          <label class="form-check-label" for="aplicarturnos">Aplicar Turnos a Usuarios</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.bloqueafac "  type="checkbox" true-value="S" false-value="N" id="bloquearfacalimprimir">
                          <label class="form-check-label" for="bloquearfacalimprimir">Bloquear Facturas al Imprimir</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.bloquearem "  type="checkbox" true-value="S" false-value="N" id="bloquearem">
                          <label class="form-check-label" for="bloquearem">Bloquear Remisiones al Imprimir</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.valesamtto "  type="checkbox" true-value="S" false-value="N" id="valesamtto">
                          <label class="form-check-label" for="valesamtto">Mandar Vales a Mantto</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cargoiva2010 "  type="checkbox" true-value="S" false-value="N" id="cargoiva2010">
                          <label class="form-check-label" for="cargoiva2010">Cargo por incremento al IVA</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.traficonvo "  type="checkbox" true-value="S" false-value="N" id="traficonvo">
                          <label class="form-check-label" for="traficonvo">Activar Mod. de Trafico Nuevo</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.usadestinos "  type="checkbox" true-value="S" false-value="N" id="usadestinos">
                          <label class="form-check-label" for="usadestinos">Utiliza Destinos en lugar de Clientes</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.nceditarcancel "  type="checkbox" true-value="S" false-value="N" id="editaconceptocan">
                          <label class="form-check-label" for="editaconceptocan">Editar Concepto en Cancelación con NC</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.nobloqueos "  type="checkbox" true-value="S" false-value="N" id="nobloqueos">
                          <label class="form-check-label" for="nobloqueos">No bloquear por Limite Crédito o Vencidos</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activardivisiones "  type="checkbox" true-value="S" false-value="N" id="activardivisiones">
                          <label class="form-check-label" for="activardivisiones">Activar Divisiones</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.optimizarvisores "  type="checkbox" true-value="S" false-value="N" id="optimizarvisores">
                          <label class="form-check-label" for="optimizarvisores">Optimizar visores (no mostrar datos)</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.dieselcalcimpto "  type="checkbox" true-value="S" false-value="N" id="dieselcalcimpto">
                          <label class="form-check-label" for="dieselcalcimpto">Calcular Impuesto en Vale de Diesel</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activadifcamb "  type="checkbox" true-value="S" false-value="N" id="diferenciacambiaria">
                          <label class="form-check-label" for="dieselcalcimpto">Activa Dif. Cambiaria (x mes)</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-contabilidad" role="tabpanel" aria-labelledby="nav-contabilidad-tab">
                    <div class="row ">
                      <div class="col-12">
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. Clientes</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaclientes" />													
                            </div>
                            <label class="col-1 text-nowrap">Param1</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.cteparam1" />													
                            </div>
                            <label class="col-1 text-nowrap">Param2</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.cteparam2" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. Deudores</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctadeudor" />													
                            </div>
                            <label class="col-1 text-nowrap">Param1</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.deuparam1" />													
                            </div>
                            <label class="col-1 text-nowrap">Param2</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.deuparam2" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. Prestamos</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaprestamos" />													
                            </div>
                            <label class="col-1 text-nowrap">Param1</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.pmoparam1" />													
                            </div>
                            <label class="col-1 text-nowrap">Param2</label>
                            <div class="col-2">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.pmoparam2" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. Proveedores</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaproveed" />													
                            </div>
                            <label class="col-1 text-nowrap">Param1</label>
                            <div class="col-2">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.proparam1" />													
                            </div>
                            <label class="col-1 text-nowrap">Param2</label>
                            <div class="col-2">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.proparam2" />													
                            </div>
                          </div>	
                      </div>
                      <div class="col-12">
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. C.Chica (Suc)</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctacchica" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. Anticipos</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaantcte" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-3 text-nowrap">Cta. Gasto Hotel</label>
                            <div class="col-3">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctagasthotel" />													
                            </div>
                        </div>	
                      </div>
                      
                      <div class="col-6">
                        <div class="row ">
                          <label class="col-6 text-nowrap mt-3">Cta. Retención Flete</label>
                          <div class="col-6 text-center">
                            <span>Cobrado</span>
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaretcob" />													
                          </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. de Fletes</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaflete" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. Descuento</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctadescto" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. Utilidad Cambiaria</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctautiltc" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. Perdida Cambiaria</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaperdtc" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. Comisiones</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctacomisio" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. IEPS</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaieps" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. Cuadre Fac.Die.</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctacuadrefacdie" />													
                            </div>
                        </div>	

                        <div class="row ">
                            <label class="col-6 text-nowrap">Cta. Gtos Financieros</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctagastosfin" />													
                            </div>
                            
                        </div>	

                        <div class="row">
                            <label class="col-6 text-nowrap">Cta. Combustibles</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm" v-model="parametros.ctacombustible" />													
                            </div>
                        </div>	
                        <div class="row">
                            <label class="col-6 text-nowrap">Cta. Incobrables</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm" v-model="parametros.ctaincobrables" />													
                            </div>
                        </div>	
                      </div>

                      <div class="col-6">
                        <div class="row ">
                          <div class="col-12 text-center">
                            <span>No Cobrado</span>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaretnocob" />													
                            </div>
                          </div>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.ligagl"  type="checkbox" true-value="S" false-value="N" id="ligacontabilidad">
                          <label class="form-check-label" for="ligacontabilidad">Liga Contabilidad</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activaivactes"  type="checkbox" true-value="S" false-value="N" id="transferiva">
                          <label class="form-check-label" for="transferiva">Activa Transferir IVA</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activacc"  type="checkbox" true-value="S" false-value="N" id="activarccosto">
                          <label class="form-check-label" for="activarccosto">Activar C.Costo</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.activacccte"  type="checkbox" true-value="S" false-value="N" id="activarccostoctes">
                          <label class="form-check-label" for="activarccostoctes">Activar C.Costo Ctes</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.contpagres "  type="checkbox" true-value="S" false-value="N" id="contabilizaresendep">
                          <label class="form-check-label" for="contabilizaresendep">Contabiliza Res. en Dep.</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.contfinanciera "  type="checkbox" true-value="S" false-value="N" id="contabilidadfinanciera">
                          <label class="form-check-label" for="contabilidadfinanciera">Contabilidad Financiera</label>
                        </div>
                        <div class="row ">
                            <label class="col-5 text-nowrap">Tpo Poliza Diario</label>
                            <div class="col-7">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.tpoldi" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-5 text-nowrap">Tpo Poliza Liq</label>
                            <div class="col-7">
                                <input type="text" class="form-control form-control-sm"  v-model="parametros.tpolliq" />													
                            </div>
                        </div>	
                        <div class="row ">
                          <div class="form-check col-5">
                              <input class="form-check-input" v-model="parametros.desglozaivagf" type="checkbox" true-value="S" false-value="N" id="desglozaivagf">
                              <label class="form-check-label" for="desglozaivagf">Desglosa IVA</label>
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-5 text-nowrap">Tpo Poliza Canc. Fac.</label>
                            <div class="col-7">
                              <input type="text" class="form-control form-control-sm" v-model="parametros.tpolcanfac" />
                          </div>
                        </div>
                        <div class="row ">
                          <label class="col-5 text-nowrap">Tpo Poliza Incobrable</label>
                            <div class="col-7">
                              <input type="text" class="form-control form-control-sm" v-model="parametros.tpolincobr" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-tipos" role="tabpanel" aria-labelledby="nav-tipos-tab">
                    <div class="row">
                      <div class="col-12 form-group" style="padding-bottom:6rem">
                        <b-table-simple hover small caption-top responsive>
                            <b-thead head-variant="light"  class="table-header">
                                <b-tr class="table-header">
                                    <b-th>Impto</b-th>
                                    <b-th>Cta IVA Acredit<br>Pendiente</b-th>
                                    <b-th>Cta IVA Acredit<br>Pagado</b-th>
                                    <b-th>Cta IVA<br>No Cobrado</b-th>
                                    <b-th>Cta IVA<br>Cobrado</b-th>
                                    <b-th>Cta Ventas<br>No Realizadas</b-th>
                                    <b-th>Cta Ventas<br>Realizada</b-th>
                                    <b-th>Cta Permisionarios<br>No Realizada</b-th>
                                    <b-th>Cta Permisionarios<br>Realizada</b-th>
                                    <b-th>Cta Otros<br>No Realizada</b-th>
                                    <b-th>Cta Otros<br>Realizada</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="parametros.tiposimpuestos">
                                <b-tr v-for="(ti,x) in parametros.tiposimpuestos" :key="x" 
                                  :class="{'selected-row': (selectedTiposImpuestos.indx == x) }" @click="selectedTiposImpuestos=ti;selectedTiposImpuestos.indx = x" >
                                    <b-td class="text-center">{{ ti.impto }}</b-td>
                                    <b-td class="text-start">{{ ti.ctaivaacreditpdte }}</b-td>
                                    <b-td class="text-center">{{ ti.ctaivaacreditp }}</b-td>
                                    <b-td class="text-center">{{ ti.ctaivanocob }}</b-td>
                                    <b-td class="text-center">{{ ti.ctaivacob }}</b-td>
                                    <b-td class="text-start">{{ ti.ctaventasnorea }}</b-td>
                                    <b-td class="text-start">{{ ti.ctaventasrea }}</b-td>
                                    <b-td class="text-start">{{ ti.ctapermisionariosnorea }}</b-td>
                                    <b-td class="text-start">{{ ti.ctapermisionariosrea }}</b-td>
                                    <b-td class="text-start">{{ ti.ctaotrosnorea }}</b-td>
                                    <b-td class="text-start">{{ ti.ctaotrosrea }}</b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td class="text-center" :variant="'secondary'" colspan="16"><p class="p-4">NO HAY TIPOS DE IMPUESTOS</p></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                      </div>
                      <div class="col-6">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.utilizasucencta"  type="checkbox" true-value="S" false-value="N" id="utilsuccta">
                          <label class="form-check-label" for="utilsuccta">Utiliza Sucursal en Cta</label>
                        </div>

                        <div class="row">
                          <div class="col-2 offset-4"><label>Pos</label></div>
                          <div class="col-2"><label>Lang</label></div>
                        </div>
                        <div class="row">
                          <label class="col-4 text-nowrap">Sucursal en Cuenta</label>
                          <div class="col-2">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.ctasucursalpos" />													
                          </div>
                          <div class="col-2">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.ctasucursallong" />													
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                          <ul class="list-unstyled d-flex justify-content-end">
                              <li class="pe-1"><button type="button" :disabled="Disabled_GR_Grupo2" @click="deleteTiposImpuestos()" class="btn btn-sm btn-secondary w-100">Borrar</button></li>
                          </ul>
                      </div>
                    </div>
                
                  </div>
                  <div class="tab-pane fade" id="nav-nomina" role="tabpanel" aria-labelledby="nav-nomina-tab">
                    <div class="row ">
                        <label class="col-2 text-nowrap">Sueldo Base</label>
                        <div class="col-5 d-flex">
                            <input type="number" class="form-control form-control-sm"  v-model="parametros.sueldobase" />													
                        </div>
                    </div>	
                    <div class="row ">
                      <label class="col-1 text-nowrap mt-3">IMSS</label>
                      <div class="col-2 text-center">
                        <span>Concepto</span>
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptoimss" />													
                      </div>
                      <div class="col-4 text-center">
                        <span>Factor</span>
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.factorimss" />													
                      </div>
                      <label class="col-1 text-nowrap mt-3">Viat.NC</label>
                      <div class="col-2 text-center">
                        <span>Concepto</span>
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptovnd" />													
                      </div>
                    </div>	
                    <div class="row ">
                      <label class="col-1 text-nowrap">ISPT</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.factorispt" />													
                      </div>
                      <div class="col-4 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptoispt" />													
                      </div>
                      <label class="col-1 text-nowrap">PPUNT</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptoppunt" />													
                      </div>
                    </div>	
                    <div class="row ">
                      <label class="col-1 text-nowrap">INFO</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptoinfo" />													
                      </div>
                      <div class="col-4 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.factorinfo" />													
                      </div>
                      <label class="col-1 text-nowrap">PASIST</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptopasist" />													
                      </div>
                    </div>	
                    <div class="row ">
                      <label class="col-1 text-nowrap ">AHOR</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptoaho" />													
                      </div>
                      <div class="col-4 text-center"></div>
                      <label class="col-1 text-nowrap">SUELDO</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptosueldo" />													
                      </div>
                    </div>	
                    <div class="row ">
                      <label class="col-1 text-nowrap ">PENSION</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptopen" />													
                      </div>
                    </div>	
                    <div class="row ">
                      <label class="col-1 text-nowrap ">FONACOT</label>
                      <div class="col-2 text-center">
                        <input type="number" class="form-control form-control-sm"  v-model="parametros.conceptofcot" />													
                      </div>
                    </div>	
                    <div class="row ">
                      <div class="col-10">
                        <b-table-simple>
                            <b-thead>
                                <b-tr class="table-header">
                                    <b-th>Kms</b-th>
                                    <b-th>Cargado</b-th>
                                    <b-th>Vacio</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="parametros.nominas ">
                                <b-tr v-for="(guia,x) in parametros.nominas" :key="x" >
                                <b-td class="text-end">{{guia.numero}}</b-td>
                                <b-td class="text-end">{{guia.descripcion}}</b-td>
                                <b-td class="text-end">{{guia.peso}}</b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr  >
                                    <b-td class="text-center" :variant="'secondary'" colspan="3"><p class="p-4">NO HAY DATOS</p></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                      </div>
                      <div class="col-2">
                        <button class="btn btn-sm btn-outline-success btn-block">Agregar</button>
                        <button class="btn btn-sm btn-outline-danger btn-block">Borrar</button>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-permisionarios" role="tabpanel" aria-labelledby="nav-permisionarios-tab">
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Seguro</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaseguro" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Reparto</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctareparto" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Maniobras</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctamaniobras" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Autopistas</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaautopistas" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Recolección</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctarecoleccion" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Otros</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaotros" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Cta. Doble Oper</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.ctadobleoper" />													
                        </div>
                    </div>	
                  </div>
                  <div class="tab-pane fade" id="nav-servidor" role="tabpanel" aria-labelledby="nav-servidor-tab">
                    <p>Información del Servidor SMTP (mandar correos)</p>
                    <div class="row ">
                        <label class="col-2 text-nowrap">Server</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.smtpserver" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">User</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.smtpuser" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Password</label>
                        <div class="col-6">
                            <input type="password" class="form-control form-control-sm"  v-model="parametros.smtppswd" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Dir. de Retorno</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.smtpreply" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Puerto</label>
                        <div class="col-3">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.smtppuerto" />													
                        </div>
                        <div class="form-check col-3">
                          <input class="form-check-input"  v-model="parametros.smtpusetls "  type="checkbox" true-value="S" false-value="N" id="tls">
                          <label class="form-check-label" for="tls">Utilizar TLS</label>
                        </div>
                        <div class="form-check col-3">
                          <input class="form-check-input"  v-model="parametros.smtpusessl "  type="checkbox" true-value="S" false-value="N" id="ssl">
                          <label class="form-check-label" for="ssl">Utilizar SSL</label>
                        </div>
                    </div>	
                    <div class="row">
                      <label class="col-2 text-nowrap text-right">Formato</label>
                      <div class="col-6">
                        <select class="form-control form-control-sm" v-model="parametros.smtpmessageformat">
                          <option value="" selected disabled>Selecciona una opcion...</option>
                          <option value="texto">Texto</option>
                          <option value="html">HTML</option>
                        </select>
                      </div>
                    </div>
                    <div class="row pt-4">
                        <label class="col-10 offset-2">Directorio OMNITRACS para Mensajes:</label>
                        <label class="col-2 text-nowrap">Directorio</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.omnimsn" />													
                        </div>
                        <div class="form-check col-9 offset-3">
                          <input class="form-check-input"  v-model="parametros.usanoecoomni "  type="checkbox" true-value="S" false-value="N" id="usanoecoomni">
                          <label class="form-check-label" for="usanoecoomni">Usa Numero Economico (OMNITRACS)</label>
                        </div>
                    </div>	
                    <div class="row pt-4">
                        <div class="form-check col-9 offset-3">
                          <input class="form-check-input"  v-model="parametros.cfdooutlook "  type="checkbox" true-value="S" false-value="N" id="cfdooutlook">
                          <label class="form-check-label" for="cfdooutlook">Utilizar OutLook para enviar CFD</label>
                        </div>
                        <label class="col-2 text-nowrap">Asunto CFD:</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.cfdasunto" />													
                        </div>
                    </div>	
                  </div>
                  <div class="tab-pane fade" id="nav-avisos" role="tabpanel" aria-labelledby="nav-avisos-tab">
                    <div class="row border">
                      <div class="col-9 bkg-cyan">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.licencias "  type="checkbox" true-value="S" false-value="N" id="licvencidas">
                          <label class="form-check-label" for="licvencidas">Licencias Vencidas y por Vencer</label>
                        </div>
                      </div>
                      <div class="col-3 bkg-cyan">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Dias</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.diasliq" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-9 bkg-cyan">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.fianzas "  type="checkbox" true-value="S" false-value="N" id="fianzasven">
                          <label class="form-check-label" for="fianzasven">Fianzas Vencidas y por Vencer</label>
                        </div>
                      </div>
                      <div class="col-3 bkg-cyan">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Dias</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.diasfia" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-9">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.liquidaciones "  type="checkbox" true-value="S" false-value="N" id="liqabiertas">
                          <label class="form-check-label" for="liqabiertas">Liquidaciones Abiertas</label>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Dias</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.diasliq" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-9">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cobranza "  type="checkbox" true-value="S" false-value="N" id="cobranzaven">
                          <label class="form-check-label" for="cobranzaven">Cobranza Vencida y por Vencer</label>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Dias</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.diascob" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-9 bkg-cyan">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.ordenes "  type="checkbox" true-value="S" false-value="N" id="ordenesem">
                          <label class="form-check-label" for="ordenesem">Ordenes de Embarque sin Remisionar</label>
                        </div>
                      </div>
                      <div class="col-3 bkg-cyan">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Dias</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.diasord" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-9 bkg-cyan">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.traficos "  type="checkbox" true-value="S" false-value="N" id="trafdesp">
                          <label class="form-check-label" for="trafdesp">Trafico sin Despachar</label>
                        </div>
                      </div>
                      <div class="col-3 bkg-cyan"></div>
                      <div class="col-9">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.remisiones "  type="checkbox" true-value="S" false-value="N" id="remisionessfac">
                          <label class="form-check-label" for="remisionessfac">Remisiones sin Facturar</label>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Dias</label>
                            <div class="col-7">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.diasrem" />													
                            </div>
                        </div>	
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col-12">
                        <h4>Banner de Encabezado para avisos de Tráfico:</h4>
                      </div>
                      <div class="col-12 form-group">
                          <label class="text-nowrap">Correos Notificar Cambio Ruta (separar con ;)</label>
                          <textarea class="form-control form-control-sm" rows="3" name=""  v-model="parametros.correocambioruta "></textarea>													
                      </div>	
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-formatos" role="tabpanel" aria-labelledby="nav-formatos-tab">
                    <div class="row">
                      <div class="col-12 d-flex">
                        <label class="pe-2">Parámetros</label>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.parametros" value="G" selected type="radio" id="parm">
                          <label class="form-check-label" for="parm">GLOBALES</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.parametros" value="L" disabled type="radio" id="parm">
                          <label class="form-check-label" for="parm">LOCALES</label>
                        </div>
                      </div>
                    </div>
                    <div class="row ">
                        <label class="col-2 text-nowrap">Formato Factura 1</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fmtfac1" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Formato Factura 2</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fmtfac2" />													
                        </div>
                    </div>	
                    <div class="row ">
                        <label class="col-2 text-nowrap">Formato Factura 3</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fmtfac3" />													
                        </div>
                    </div>	

                    <div class="row pt-3">
                        <label class="col-2 text-nowrap">Formato Remisión 1</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fmtrem1" />													
                        </div>
                    </div>	
                    <div class="row">
                        <label class="col-2 text-nowrap">Formato Remisión 2</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fmtrem2" />													
                        </div>
                    </div>	
                    <div class="row">
                        <label class="col-2 text-nowrap">Formato Remisión 3</label>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fmtrem3" />													
                        </div>
                    </div>	
                 
                    <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <label class="col-4 text-nowrap">Serie Default Fac</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.seriefac" />													
                            </div>
                          </div>	
                          <div class="row">
                            <label class="col-4 text-nowrap">Serie Default Rem</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.serierem" />													
                            </div>
                          </div>
                          <div class="row">
                            <label class="col-4 text-nowrap">Partidas x Facturas</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.pdasxfac" />													
                            </div>
                          </div>
                          <div class="form-check offset-2">
                            <input class="form-check-input"  v-model="parametros.dlsingles "  type="checkbox" true-value="S" false-value="N" id="dlsingles">
                            <label class="form-check-label" for="dlsingles">Dolares en Inglés</label>
                          </div>
                          <div class="form-check  offset-2">
                            <input class="form-check-input" v-model="parametros.anticipo3en1 "  type="checkbox" true-value="S" false-value="N" id="anticipo3en1">
                            <label class="form-check-label" for="anticipo3en1">en Anticipos Imprimir 3 en 1</label>
                          </div>
                          <div class="row">
                            <label class="col-4 text-nowrap">Decimales Notas</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.decimalnotas" />													
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <label class="col-4 text-nowrap">Serie Fac NoFiscal1</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.serieinvoice1" />													
                            </div>
                          </div>	
                          <div class="row">
                            <label class="col-4 text-nowrap">Serie Fac NoFiscal2</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.serieinvoice2" />													
                            </div>
                          </div>
                          <div class="row">
                            <label class="col-4 text-nowrap">Serie Fac Remisión</label>
                            <div class="col-3">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.seriefacremision" />													
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-facil" role="tabpanel" aria-labelledby="nav-facil-tab">
                    <div class="row">
                        <label class="col-3 text-nowrap">Maniobras</label>
                        <div class="col-4">
                            <input type="number" class="form-control form-control-sm"  v-model="parametros.facmaniobras" />													
                        </div>
                        <label class="col-3">/Ton</label>
                    </div>	
                    <div class="row">
                        <label class="col-3 text-nowrap">Viaticos</label>
                        <div class="col-4">
                            <input type="number" class="form-control form-control-sm"  v-model="parametros.facviaticos" />													
                        </div>
                        <label class="col-3">/Dia</label>
                      </div>	
                      <div class="row">
                        <label class="col-3 text-nowrap">Refacciones</label>
                        <div class="col-4">
                          <input type="number" class="form-control form-control-sm"  v-model="parametros.facrefaccion" />													
                        </div>
                        <label class="col-3">/Km</label>
                      </div>	
                      <div class="row pt-5">
                        <label class="col-3 text-nowrap">% Utilizacion</label>
                        <div class="col-4">
                          <input type="number" class="form-control form-control-sm"  v-model="parametros.porcfacilidades" />													
                        </div>
                      </div>	
                     
                  </div>
                  <div class="tab-pane fade" id="nav-factura" role="tabpanel" aria-labelledby="nav-factura-tab">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfddefault" true-value="S" false-value="N" type="checkbox" id="cfddefault">
                          <label class="form-check-label" for="cfddefault">Factura Electrónica por Omisión</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.rcfddefault" true-value="S" false-value="N"  type="checkbox" id="rcfddefault">
                          <label class="form-check-label" for="rcfddefault">Remisión Electrónica por Omisión</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdrevisar" true-value="S" false-value="N" type="checkbox" id="revisarcomprobante">
                          <label class="form-check-label" for="revisarcomprobante">Revisar Comprobante Generado</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdpagare" true-value="S" false-value="N" type="checkbox" id="pagare">
                          <label class="form-check-label" for="pagare">Factura con Pagaré</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdcalcsdocompagtod" true-value="S" false-value="N" type="checkbox" id="cfd_calcsdocompagtod">
                          <label class="form-check-label" for="cfd_calcsdocompagtod">Calc. Sdo. Comp. Pag. Todo </label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdsucdatos " true-value="S" false-value="N"  type="checkbox" id="cfd_datossucursal">
                          <label class="form-check-label" for="cfd_datossucursal">Utilizar los datos de la sucursal</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.leyenda_rs " true-value="S" false-value="N"  type="checkbox" id="leyenda_rs">
                          <label class="form-check-label" for="leyenda_rs">Incluir Leyenda de Régimen Simplificado</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.leyendanoresp " true-value="S" false-value="N"  type="checkbox" id="leyenda_noresp">
                          <label class="form-check-label" for="leyenda_noresp">Leyenda de NO Responsabilidad</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdi1hora " true-value="S" false-value="N"  type="checkbox" id="_1hora">
                          <label class="form-check-label" for="_1hora">Permitir quitar 1 Hora</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdfoliocomp" true-value="S" false-value="N"  type="checkbox" id="cfd_folioconserie">
                          <label class="form-check-label" for="cfd_folioconserie">Incluir el Folio Completo (2 dig. serie)</label>
                        </div>
                      </div>
                      <div class="col-6 pt-3">
                        <h5>Tipos de Comprobantes Digitales:</h5>
                      </div>
                      <div class="col-6">
                        <div class="row">
                              <label class="col-5 text-nowrap text-end">Tam. Letra</label>
                              <div class="col-3">
                                  <input type="number" class="form-control form-control-sm" v-model="parametros.fontsizecfd" />													
                              </div>
                          </div>	
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <label class="col-3 text-nowrap text-right">Facturación</label>
                          <div class="col-9">
                            <select class="form-control form-control-sm" v-model="parametros.cftipo">
                              <option value="CFD">Digital (CFD)</option>
                              <option value="CFDI">Digital Internet (CFDI)</option>
                              <option value="CFCBB">Impreso (CFCBB)</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-3 text-nowrap text-right">Remisiones</label>
                          <div class="col-9">
                            <select class="form-control form-control-sm" v-model="parametros.cftiporem">
                              <option value="CFD">Digital (CFD)</option>
                              <option value="CFDI">Digital Internet (CFDI)</option>
                              <option value="CFCBB">Impreso (CFCBB)</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdipagonota"  type="checkbox" true-value="S" false-value="N" id="cfdi_pagonota">
                          <label class="form-check-label" for="cfdi_pagonota">Capturar Metodo Pago en Notas</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdiusarango"  type="checkbox" true-value="S" false-value="N" id="rango_foliose">
                          <label class="form-check-label" for="rango_foliose">Usar Rangos Folios E.</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdinoimprimircves" type="checkbox" true-value="S" false-value="N" id="cfdi_cvesimpresion">
                          <label class="form-check-label" for="cfdi_cvesimpresion">No incluir las claves en la impresión</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdibitacoratimbrado" type="checkbox" true-value="S" false-value="N" id="cfdi_bitacoratimbrado">
                          <label class="form-check-label" for="cfdi_bitacoratimbrado">Bitácora de timbrado</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <label class="col-2 text-nowrap text-right">Regimen Fiscal</label>
                          <div class="col-8">
                            <select class="form-control form-control-sm" v-model="parametros.cfdcveregimen">
                              <option value=""></option>
                            </select>
                          </div>
                        </div>
                        <div class="row ">
                          <label class="col-2 text-nowrap">Imagen Fondo</label>
                          <div class="col-8 text-center">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.fondo" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Copiar a</label>
                          <div class="col-8 text-center">
                            <input type="text" class="form-control form-control-sm" v-model="parametros.cfdcopiara" />													
                          </div>
                        </div>	
                    </div>

                    <div class="row">
                        <div class="col-7">
                            <div class="row ">
                            <label class="col-4 text-nowrap">Unidad Medida (CP)</label>
                            <div class="col-8 text-center">
                              <input type="text" class="form-control form-control-sm text-uppercase" v-model="parametros.unidadmedida" />													
                            </div>
                          </div>	
                          <div class="row ">
                            <label class="col-8 text-nowrap">Avisar cuando el certificado expire en</label>
                            <div class="col-2 text-center">
                              <input type="text" class="form-control form-control-sm" v-model="parametros.cfdavisodias" />													
                            </div>
                            <label class="col-2 text-nowrap">días.</label>
                          </div>	
                          
                        </div>
                        <div class="col-5">
                          <div class="row ">
                            <label class="col-4 text-nowrap">Interés Mensual:</label>
                            <div class="col-8 text-center">
                              <input type="text" class="form-control form-control-sm text-uppercase" v-model="parametros.pagareinteres" />													
                            </div>
                          </div>	
                          
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-8 ">
                          <label class="col-9 text-nowrap">En CFDI notificar cuando los folios restantes sean</label>
                          <div class="col-3 text-center">
                            <input type="text" class="form-control form-control-sm" v-model="parametros.cfdinotificar" />													
                          </div>
                      </div>	
                      <div class="col-4 ">
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.cfdimonedaabrev"  type="checkbox" true-value="S" false-value="N" id="moneda_abrev">
                          <label class="form-check-label" for="moneda_abrev">Usar Abrev en Moneda (CFDI)</label>
                        </div>
                      </div>	
                    </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-parametros" role="tabpanel" aria-labelledby="nav-parametros-tab">
                    <div class="row">
                      <div class="col-12">
                        <div class="row ">
                          <label class="col-2 text-nowrap">Empresa</label>
                          <div class="col-2 text-center">
                            <input type="text" class="form-control form-control-sm" readonly v-model="parametros.empresa" />													
                          </div>
                          <div class="col-8 text-center">
                            <input type="text" class="form-control form-control-sm" readonly v-model="parametros.empresa_nombre" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Directorio</label>
                          <div class="col-10 text-center">
                            <input type="text" class="form-control form-control-sm" readonly v-model="parametros.directorio" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Lugar de Exp.</label>
                          <div class="col-10 text-center">
                            <input type="text" class="form-control form-control-sm"  v-model="parametros.expedicion" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <div class="col-6">
                            <div class="row ">
                              <label class="col-4 text-nowrap">Año</label>
                              <div class="col-5 text-center">
                                <input type="number" maxlength="4" class="form-control form-control-sm"  v-model="parametros.ano" />													
                              </div>
                            </div>	
                            <div class="row">
                              <label class="col-4 text-nowrap text-right">Leyenda Liq.</label>
                              <div class="col-8">
                                <select class="form-control form-control-sm" v-model="parametros.leyenda">
                                  <option value="N">Sin LEYENDA</option>
                                  <option value="1">LEYENDA 1</option>
                                  <option value="2">LEYENDA 2</option>
                                  <option value="3">LEYENDA 3</option>
                                </select>
                              </div>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.mavisos"  type="checkbox" true-value="S" false-value="N" id="op1">
                              <label class="form-check-label" for="op1">Mostrar Alertas al Iniciar el Sistema</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.sueldo"  type="checkbox" true-value="S" false-value="N" id="op2">
                              <label class="form-check-label" for="op2">Utilizar Sueldos en Liquidaciones</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.blocknet"  type="checkbox" true-value="S" false-value="N" id="op3">
                              <label class="form-check-label" for="op3">Bloquear Ejecuciones Remotas</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.liqaceptaotros"  type="checkbox" true-value="S" false-value="N" id="op4">
                              <label class="form-check-label" for="op4">Poder aceptar conceptos de otras unidades (Liquidaciones)</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.coperfacpagadas"  type="checkbox" true-value="S" false-value="N" id="op5">
                              <label class="form-check-label" for="op5">Poder cambiar operador en facturas pagadas</label>
                            </div>
                      <div class="form-check">
                        <input class="form-check-input"  v-model="parametros.language"  type="checkbox" true-value="S" false-value="N" id="op6">
                        <label class="form-check-label" for="op6">Inglés</label>
                      </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.liqsinvalores"  type="checkbox" true-value="S" false-value="N" id="op7">
                              <label class="form-check-label" for="op7">No Mostrar Valores en Liquidacion (Bandas 2)</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.permiteremision"  type="checkbox" true-value="S" false-value="N" id="op8">
                              <label class="form-check-label" for="op8">Permitir editar Remisiones aun Liquidadas.</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.nogenliqrut"  type="checkbox" true-value="S" false-value="N" id="op9">
                              <label class="form-check-label" for="op9">No Genera Liquidación de Rutas</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.asiantotros"  type="checkbox" true-value="S" false-value="N" id="op10">
                              <label class="form-check-label" for="op10">Asignar Anticipos en Liq. de todas las unidades</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.fichasdepositoclass"  type="checkbox" true-value="S" false-value="N" id="op11">
                              <label class="form-check-label" for="op11">Fichas Deposito (Class)</label>
                            </div>
                            
                          </div>	
                          <div class="col-6">
                            <div class="row ">
                              <label class="col-5 text-nowrap">Seguro</label>
                              <div class="col-5 text-center">
                                <input type="number"  class="form-control form-control-sm"  v-model="parametros.seguro" />													
                              </div>
                              <label class="col-2 text-start">%</label>				
                            </div>	
                            <div class="row ">
                              <label class="col-5 text-nowrap">I.V.A.</label>
                              <div class="col-5 text-center">
                                <input type="number" class="form-control form-control-sm"  v-model="parametros.iva" />									
                              </div>
                              <label class="col-2 text-start">%</label>				
                            </div>	
                            <div class="row ">
                              <label class="col-5 text-nowrap">Retención</label>
                              <div class="col-5 text-center">
                                <input type="number" step="any" min="0" class="form-control form-control-sm"  v-model="parametros.retencion" />				
                              </div>
                              <label class="col-2 text-start">%</label>				
                            </div>	
                            <div class="row ">
                              <label class="col-5 text-nowrap text-end">Polizas Cuadrar hasta</label>
                              <div class="col-7 text-center">
                                <input type="number" step="any" min="0" class="form-control form-control-sm"  v-model="parametros.poldif" />													
                              </div>
                            </div>	
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.preview"  type="checkbox" true-value="S" false-value="N" id="op12">
                              <label class="form-check-label" for="op12">Usar Vista Previa</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.origendestino"  type="checkbox" true-value="S" false-value="N" id="op13">
                              <label class="form-check-label" for="op13">Origen / Destino como Ciudad</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.oembarqueestrafico"  type="checkbox" true-value="S" false-value="N" id="op14">
                              <label class="form-check-label" for="op14">O. Embarque Es un Trafico</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.rutaenrem"  type="checkbox" true-value="S" false-value="N" id="op15">
                              <label class="form-check-label" for="op15">Ruta y Convenio Exigible en Rem.</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.untraficoactivo"  type="checkbox" true-value="S" false-value="N" id="op16">
                              <label class="form-check-label" for="op16">Permitir solo un tráfico activo</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input"  v-model="parametros.notasmultifac"  type="checkbox" true-value="S" false-value="N" id="op17">
                              <label class="form-check-label" for="op17">Aplica Nota Múltiples Facturas</label>
                            </div>
                          </div>
                        </div>	
                        <div class="row ">
                          <div class="col-6">
                            <div class="row ">
                              <label class="col-3 text-nowrap">Bitácora Uni</label>
                              <div class="col-9 text-center">
                                <input type="text" class="form-control form-control-sm" maxlength="12" v-model="parametros.bitauni" />													
                              </div>
                            </div>	
                            <div class="row ">
                              <label class="col-3 text-nowrap">Bitácora Ope</label>
                              <div class="col-9 text-center">
                                <input type="text" class="form-control form-control-sm" maxlength="12"  v-model="parametros.bitaope" />													
                              </div>
                            </div>	
                            <div class="row ">
                              <label class="col-3 text-nowrap">URL Servicio</label>
                              <div class="col-9 text-center">
                                <input type="text" class="form-control form-control-sm" maxlength="12"  v-model="parametros.urlserv" />													
                              </div>
                            </div>	
                          </div>
                          <div class="col-6">
                            <div class="row ">
                              <label class="col-3 text-nowrap">Servicio Vacio</label>
                              <div class="col-9 text-center">
                                <input type="number" class="form-control form-control-sm" maxlength="3" v-model="parametros.svcvacio" />													
                              </div>
                            </div>	
                            <div class="row ">
                              <label class="col-3 text-nowrap">Servicio Cruce</label>
                              <div class="col-9 text-center">
                                <input type="number" class="form-control form-control-sm" maxlength="3"  v-model="parametros.svccruce" />													
                              </div>
                            </div>	
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-remisiones" role="tabpanel" aria-labelledby="nav-remisiones-tab">
                    <div class="form-check pb-3">
                      <input class="form-check-input" v-model="parametros.remajustarfac" type="checkbox" true-value="S" false-value="N" id="remisiones">
                      <label class="form-check-label" for="remisiones">Ajustar Diferencia al Facturar</label>
                    </div>
                    <div class="row">
                      <label class="col-3 text-nowrap">Valor Fijo de CP</label>
                      <div class="col-4">
                        <input type="text" class="form-control form-control-sm"  v-model="parametros.valorfijocp" />													
                      </div>
                      <label class="col-4">(solo formato estandar)</label>
                    </div>	
                    <div class="form-check pt-2" >
                      <input class="form-check-input"  v-model="parametros.cuotafija_cfdi "  type="checkbox" true-value="S" false-value="N" id="cuotafija_cfdi">
                      <label class="form-check-label" for="cuotafija_cfdi">Aplicar Cuota Fija a Carta Porte a Timbrar (RPT_CartaPorte_CFDI)</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input"  v-model="parametros.viajeobliga "  type="checkbox" true-value="S" false-value="N" id="viaje">
                      <label class="form-check-label" for="viaje">Viaje Obligatorio y Unico</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input"  v-model="parametros.remsolomerc_cfdi "  type="checkbox" true-value="S" false-value="N" id="remcfdi_solomercancias">
                      <label class="form-check-label" for="remcfdi_solomercancias">Incluir solo mercancías en los comprobantes</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input"  v-model="parametros.ubicacioncp_cfdi "  type="checkbox" true-value="S" false-value="N" id="remcfdi_incluirdireccion">
                      <label class="form-check-label" for="remcfdi_incluirdireccion">Incluir dirección en las ubicaciones (complemento carta porte)</label>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-facturas" role="tabpanel" aria-labelledby="nav-facturas-tab">
                    <div class="row" >
                      <div class="col-12 form-group" >
                        <p>Capture las códigos del SAT para la facturas de fletes</p>
                      </div>
                      <div class="col-12 form-group pb-5" >
                          <b-table-simple hover small caption-top responsive>
                              <b-thead head-variant="light"  class="table-header">
                                  <b-tr class="table-header">
                                      <b-th>Campo</b-th>
                                      <b-th>Clave</b-th>
                                      <b-th></b-th>
                                      <b-th>Descripción</b-th>
                                  </b-tr>
                              </b-thead>
                              <b-tbody v-if="parametros.cvesfacfletes">
                                  <b-tr v-for="(ti,x) in parametros.cvesfacfletes" :key="x" 
                                    :class="{'selected-row': (selectedTiposImpuestos.indx == x) }"
                                    @click="selectedTiposImpuestos=ti;selectedTiposImpuestos.indx = x" >
                                      <b-td class="text-center">{{ ti.impto }}</b-td>
                                      <b-td class="text-start">{{ ti.ctaivaacreditpdte }}</b-td>
                                      <b-td class="text-center">{{ ti.ctaivaacreditp }}</b-td>
                                      <b-td class="text-center">{{ ti.ctaivanocob }}</b-td>
                                  </b-tr>
                              </b-tbody>
                              <b-tbody v-else>
                                  <b-tr>
                                      <b-td class="text-center" :variant="'secondary'" colspan="4"><p class="p-4">NO HAY DATOS</p></b-td>
                                  </b-tr>
                              </b-tbody>
                          </b-table-simple>
                      </div>
                      <lookUpCtrl :objeto="parametros" :label="'Cve Unidad'" :cols="[1,2,9]" :archivo="string" :campo="string" :descripcion="string" :model="'satunidad'" v-model="parametros.cvefacunidad" />
                      <lookUpCtrl v-if="$ext == 'fng' || $ext == 'tex'" :objeto="parametros" :label="'Factura Anticipo'" :cols="[1,2,9]" :archivo="string" :campo="string" :descripcion="string" :model="'facanticipo'" v-model="parametros.facanticipo" />
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-gps" role="tabpanel" aria-labelledby="nav-gps-tab">
                    <div class="row ">
                      <label class="col-2 text-nowrap">Web service local</label>
                      <div class="col-2 text-center">
                        <input type="text" class="form-control form-control-sm" readonly v-model="parametros.gpstraficows" />													
                      </div>
                      <button disabled type="button" class="btn btn-sm btn-outline-secondary">Revisar Servicio</button>
                    </div>	
                    <div class="row">
                      <label class="col-4 text-nowrap text-right">Servicio</label>
                        <div class="col-8">
                          <select class="form-control form-control-sm" disabled v-model="parametros.gps_servicio">
                            
                          </select>
                        </div>
                    </div>
                    <div class="row">
                      <div class="form-check">
                        <input class="form-check-input"  v-model="parametros.gps_opciones_1" disabled  type="checkbox" true-value="S" false-value="N" id="op1">
                        <label class="form-check-label" for="op1">Activar Revisiones Automáticas</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input"  v-model="parametros.gps_opciones_2" disabled type="checkbox" true-value="S" false-value="N" id="op2">
                        <label class="form-check-label" for="op2">Servicio Actualiza Estatus Tráfico</label>
                      </div>
                    </div>	

                    <div class="row ">
                      <label class="col-2 text-nowrap">Correo(s) Notif.</label>
                      <div class="col-2 text-center">
                        <input type="text" class="form-control form-control-sm" placeholder="separar con comas (,) para más de un correo" disabled v-model="parametros.gps_correonotif" />													
                      </div>
                    </div>	
                    <lookUpCtrl :objeto="parametros" :label="'GeoZona Default'" :cols="[1,2,9]" :archivo="'gpsGeoZonas'" :campo="'id'" :descripcion="'nombre'" :model="'gpszonadefault'" v-model="parametros.gpszonadefault" />
                    <div class="row">
                      <label class="col-2 text-nowrap">Radio Zona Default</label>
                      <div class="col-2 text-center">
                        <input type="text" class="form-control form-control-sm" v-model="parametros.gpsradio" />													
                      </div>
                      <label class="col-2 text-nowrap">mts.</label>
                    </div>	
                  </div>
                  <div class="tab-pane fade" id="nav-app" role="tabpanel" aria-labelledby="nav-app-tab">
                      <div class="form-check">
                        <input class="form-check-input"  v-model="parametros.appkmstiempos "  type="checkbox" true-value="S" false-value="N" id="appkmstiempos">
                        <label class="form-check-label" for="appkmstiempos">Requerir kms en aplicación de tiempos</label>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="nav-contabilidad2" role="tabpanel" aria-labelledby="nav-contabilidad2-tab">
                    <div class="row ">
                      <div class="col-4">
                          <label class="col-3 text-nowrap">Cta. Acreedores</label>
                          <div class="col-3">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.ctaacreedor" />													
                          </div>
                      </div>	
                      <div class="col-4">
                        <label class="col-1 text-nowrap">Param1</label>
                          <div class="col-2">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.acreeparam1" />													
                          </div>
                      </div>	
                      <div class="col-4">
                        <label class="col-1 text-nowrap">Param2</label>
                          <div class="col-2">
                              <input type="text" class="form-control form-control-sm"  v-model="parametros.acreeparam2" />													
                          </div>
                      </div>	
                     
                    
                      <div class="col-7">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Cta. Diesel x Fac.</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm" v-model="parametros.ctadiexfac" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-5 text-nowrap">Cta. Diesel U.Gas</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm" v-model="parametros.ctadieugas" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-7">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Fac. Diesel Cuadrar hasta</label>
                            <div class="col-6">
                              <input type="number" class="form-control form-control-sm" v-model="parametros.facdiehasta" />													
                            </div>
                            <label class="col-5 text-nowrap">en Monto</label>
                        </div>	
                      </div>
                      <div class="col-5">
                        <div class="row ">
                            <div class="col-6">
                              <input type="number" class="form-control form-control-sm"  v-model="parametros.facdiehastalts" />													
                            </div>
                            <label class="col-5 text-nowrap">en Litros</label>
                        </div>	
                      </div>

                      <div class="col-7">
                        <div class="row ">
                            <label class="col-5 text-nowrap">Cta. IAVE x Fac.</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm" maxlength="14"  v-model="parametros.ctaiavexfac" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-5 text-nowrap">Cta. IAVE Gasto</label>
                            <div class="col-6">
                                <input type="text" class="form-control form-control-sm"  maxlength="14" v-model="parametros.ctaiavegasto" />													
                            </div>
                        </div>	
                      </div>
                      <div class="col-12">
                        <div class="row ">
                            <label class="col-4 offset-4 text-nowrap">Cobrado</label>
                            <label class="col-4 text-nowrap">No Cobrado</label>
                        </div>	
                        <div class="row ">
                            <label class="col-4 text-nowrap">Cta. Ret. Local</label>
                            <div class="col-4">
                                <input type="text" class="form-control form-control-sm" maxlength="14"  v-model="parametros.ctaretnomloccob" />													
                            </div>
                            <div class="col-4">
                                <input type="text" class="form-control form-control-sm" maxlength="14"  v-model="parametros.ctaretnomlocnocob" />													
                            </div>
                        </div>	
                        <div class="row ">
                            <label class="col-4 offset-4 text-nowrap">Autoconsumo</label>
                        </div>	
                        <div class="row ">
                          <div class="col-6">
                            <label class="col-4 text-nowrap">Cta. Tránsito Diesel</label>
                            <div class="col-4">
                              <input type="text" class="form-control form-control-sm"  maxlength="14" v-model="parametros.ctadietsto" />													
                            </div>
                          </div>
                          <div class="col-6">
                            <label class="col-4 text-nowrap">Conc. Fact. Diesel</label>
                            <div class="col-4">
                              <input type="number" class="form-control form-control-sm"   v-model="parametros.concfacdiesel" />													
                            </div>
                          </div>
                        </div>	
                        <div class="row ">
                          <div class="col-6">
                            <label class="col-4 text-nowrap">Cta. Inventario</label>
                            <div class="col-4">
                              <input type="text" class="form-control form-control-sm"  maxlength="14" v-model="parametros.ctadieinvauto" />													
                            </div>
                          </div>
                          <div class="col-6">
                            <label class="col-4 text-nowrap">Conc.Mov.Locales</label>
                            <div class="col-4">
                              <input type="number" class="form-control form-control-sm"   v-model="parametros.concfacmovloc" />													
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-7">
                        <h4>Contabilidad Fiscal (ctas Orden)</h4>
                          <div class="row ">
                              <label class="col-5 text-nowrap">Cta. Proveedor Factoraje</label>
                              <div class="col-6">
                                  <input type="text" class="form-control form-control-sm" maxlength="14"  v-model="parametros.ctaprovfactorajeco" />													
                              </div>
                          </div>	
                          <div class="row ">
                              <label class="col-5 text-nowrap">Cta. Ganancia Camb. Ejercida</label>
                              <div class="col-6">
                                  <input type="text" class="form-control form-control-sm"  maxlength="14" v-model="parametros.ctagananciacambeje" />													
                              </div>
                          </div>	
                          <div class="row ">
                              <label class="col-5 text-nowrap">Cta. Perdida Camb. Ejercida</label>
                              <div class="col-6">
                                  <input type="text" class="form-control form-control-sm"  maxlength="14" v-model="parametros.ctaperdidacambeje" />													
                              </div>
                          </div>	
                        </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-ultragas" role="tabpanel" aria-labelledby="nav-ultragas-tab">
                    <div class="row">
                      <div class="col-12 ">
                        <lookUpCtrl v-if="parametros" disabled :objeto="parametros" :label="'Proveedor'" :cols="[1,2,9]" :archivo="'cpProveedores'" :campo="'prove'" :descripcion="'nombre'" v-model="parametros.ugasprove" />
                        <div class="row ">
                          <label class="col-2 text-nowrap">Usuario</label>
                          <div class="col-6">
                              <input type="text" class="form-control form-control-sm" disabled v-model="parametros.ugasuser" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Contraseña</label>
                          <div class="col-6">
                              <input type="password" class="form-control form-control-sm" disabled v-model="parametros.ugaspsw" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Cta. Tránsito Diesel</label>
                          <div class="col-6">
                              <input type="text" maxlength="14" class="form-control form-control-sm"  v-model="parametros.ctadietstougas" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Cta. Inventario</label>
                          <div class="col-6">
                              <input type="text" maxlength="14" class="form-control form-control-sm"  v-model="parametros.ctadieinvugas" />													
                          </div>
                        </div>	
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.dplusactivar"  type="checkbox" true-value="S" false-value="N" id="ActivarDieselPlus">
                          <label class="form-check-label" for="ActivarDieselPlus">Activar Diesel Plus</label>
                        </div>
                        <div class="row ">
                          <label class="col-2 text-nowrap">Compañia</label>
                          <div class="col-6">
                              <input type="text" class="form-control form-control-sm" disabled v-model="parametros.dpluscia" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Usuario</label>
                          <div class="col-6">
                              <input type="text" class="form-control form-control-sm" disabled v-model="parametros.dplususer" />													
                          </div>
                        </div>	
                        <div class="row ">
                          <label class="col-2 text-nowrap">Contraseña</label>
                          <div class="col-6">
                              <input type="password" class="form-control form-control-sm" disabled v-model="parametros.dpluspsw" />													
                          </div>
                        </div>	
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-cartaporte" role="tabpanel" aria-labelledby="nav-cartaporte-tab">
                    <div class="row">
                      <div class="col-6">
                        <h5>Incluir en Peso Vehicular Bruto</h5>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.ccppesobrutomrc "  type="checkbox" true-value="S" false-value="N" id="pv1">
                          <label class="form-check-label" for="pv1">Incluir el peso de las mercancías</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.ccppesobrutorem "  type="checkbox" true-value="S" false-value="N" id="pv2">
                          <label class="form-check-label" for="pv2">Incluir el peso de los remolques</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input"  v-model="parametros.ccppesobrutodol "  type="checkbox" true-value="S" false-value="N" id="pv3">
                          <label class="form-check-label" for="pv3">Incluir el peso del dolly</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row ">
                            <label class="col-4 text-nowrap">Versión</label>
                            <div class="col-8">
                                <input type="text" maxlength="3" class="form-control form-control-sm"  v-model="parametros.ccpversion" />													
                            </div>
                        </div>	
                      </div>
                    </div>
                  </div>
                </div>             
              </div>
            </template>
          </Parameters>
          
          <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
            <div class="card position-relative border-0">
              <div class="card-body p-0">
                <div class="text-center pt-4 pb-3">
                  <label  for="foto-perfil"><Profile :usuario="user" :size="'xl'" style="cursor:pointer" /></label>
                  <input type="file" class="d-none" id="foto-perfil" @change="uploadProfile($event)">
                  <h6 class="mt-2 text-black text-uppercase">{{user.sub}}</h6>
                  <h6 class="mt-2 text-black"><small>{{user.email}}</small></h6>
                  <div class="p-3 text-start">
                    <h6 class="mt-2 text-black">Servidor: {{user.servidor}}</h6>
                    <h6 class="mt-2 text-black">BD: {{user.ext}}</h6>
                    <h6 class="mt-2 text-black">Versión: {{user.version}}</h6>
                    <h6 class="mt-2 text-black">{{user.empresa}}</h6>
                  </div>  
                </div>  
              </div>

              <div class="card-footer p-0 ">
                <hr />
                <div class="px-3">
                  <a class="btn btn-iasc-secondary d-flex flex-center w-100" href="#" @click="logout()">
                    <span class="me-2" data-feather="log-out"> </span>Cerrar Sesión
                  </a>
                </div>
              
                <div class="my-2 text-center fw-bold fs--2 text-600"><a class="text-600 mx-1" href="https://www.ingavanzada.com">www.ingavanzada.com</a>&bull;<a class="text-600 ms-1" href="#!">IASC &copy; {{year}}</a></div>
              </div>
            </div>
          </div>
        </li>
      </ul>

  </nav>
</template>


<script>
import axios from 'axios'

import Profile from '@/components/Profile.vue'
import handleGlobals from '@/mixins/handleGlobals';

import AccountTag from 'vue-material-design-icons/AccountTag.vue';
import AttachmentPlus from 'vue-material-design-icons/AttachmentPlus.vue';
import ListStatus from 'vue-material-design-icons/ListStatus.vue';
import MessagePlusOutline from 'vue-material-design-icons/MessagePlusOutline.vue';
import EmailOutline from 'vue-material-design-icons/EmailOutline.vue';
import EmailOpenOutline from 'vue-material-design-icons/EmailOpenOutline.vue';
import Moon from 'vue-material-design-icons/WeatherNight.vue';
import Sun from 'vue-material-design-icons/WhiteBalanceSunny.vue';
import Bell from 'vue-material-design-icons/BellOutline.vue';
import lookUpCtrl from './LookUpCtrl.vue';

import moment from 'moment'
import Parameters from '@/components/PopupWindow.vue';

export default {
  name: 'SideBarNav',
  mixins:[handleGlobals],
  components:{Parameters,lookUpCtrl,EmailOutline,Moon,Bell,EmailOpenOutline,Sun,Profile,AccountTag,MessagePlusOutline,AttachmentPlus,ListStatus},
  data(){
    return{
      user: JSON.parse(Buffer.from(this.$store.state.iasctratkn.split('.')[1], 'base64').toString()),
      notificaciones: [],
      nuevosMensajes: 0,
      openParameters:false,
      logotipo: '',
      year: new Date().getFullYear(),
      selectedTiposImpuestos: 0
    }
  },
  created(){
    //this.getNotificaciones()
    //console.log(JSON.parse(Buffer.from(this.$store.state.iasctratkn.split('.')[1], 'base64').toString()))
  },
  computed:{
     routePath(){
       return this.$route.path 
    },
    basePath(){
        return process.env.VUE_APP_BASE_SUBFOLDER
    },
  },
  methods:{
    logout(){
      this.$store.dispatch('logout')
      this.$router.push({name: 'login'})
    },
    openParams(){
      this.updateParams()
      this.openParameters = true
    },
    saveParameters(){
      console.log(this.parametros)
      fetch(this.$api+'/rdx', {
            method: "POST",
            headers: { "Content-Type": "application/json" ,
                       'Authorization' : this.$store.state.iasctratkn },
            body: JSON.stringify({  accion: "metodo", 
                                    archivo: "Params", 
                                    metodo: "INIWriteParams",
                                    params: {parametros: this.parametros} })
        }).then((response)=>{
            if(response.ok){
                return response.json();
            }   
        }).then((data)=>{
          this.$store.dispatch('saveParams',data)
          this.openParameters = false
        })
    },
    getNotificaciones(){
        axios.get(this.$api+'/notificaciones/findtopbar',{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : this.$store.state.iasctratkn
            }
        })
        .then((res)=>{
            this.notificaciones = res.data.notificaciones
            this.nuevosMensajes = res.data.nuevosMensajes

        }).catch((err)=>{
            console.log(err)
        })
    },
    formatFromNow(fecha){
        return moment(fecha).fromNow()
    },
    marcarTodas(){
        axios.post(this.$api+'/notificaciones/marcar',{},{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : this.$store.state.iasctratkn
            }
        })
        .then(()=>{
          this.getNotificaciones()
        }).catch((err)=>{
            console.log(err)
        })
    },
    marcar(nota){
      axios.post(this.$api+'/notificaciones/marcar/'+nota.nota.id,{leida:nota.nota.leida},{
          headers: {
              'Content-Type' : 'application/json',
              'Authorization' : this.$store.state.iasctratkn
          }
      })
      .then(()=>{
        this.getNotificaciones()
      }).catch((err)=>{
          console.log(err)
      })
    },
    deleteTiposImpuestos(){

    },
    uploadProfile($event){
        var image 
        if($event.target.files.length >= 1){
            image =  $event.target.files[0]
        }

        if(image.size <= 1000000){
            if( image.type == 'image/jpg' || image.type == 'image/jpeg' || image.type == 'image/png'){
                
                let fm = new FormData()
                fm.append('foto',image) 
               
                axios.post(this.$api+'/usuarios/setProfile',fm,{
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization' : this.$store.state.iasctratkn
                    }
                }).then(()=>{
                   
                })
            }else{
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'El tipo de archivo no se permite',
                    type: 'danger'
                });
            }
        }else{
            this.$notify({
                group: 'iasc',
                title: 'Error',
                text: 'El archivo debe pesar menos de 1Mb',
                type: 'danger'
            });
        }
      }
    }
  }
</script>